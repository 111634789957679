import * as React from 'react';
import ErrorPageContent from '../components/ErrorPageContent';

// markup
const EmailVerificationErrorPage = () => {
  return (
    <ErrorPageContent
      errorTitle={'パスワードの更新ができませんでした'}
      errorMessage={
        'リンクの有効期限が切れている可能性があります。\nリセットメールの送信をやり直してください。'
      }
    />
  );
};

export default EmailVerificationErrorPage;
